<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 18:07:08
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-09-15 18:28:52
-->
<template>
  <div class="status-list-wrapper">
    <div
      @click="handleStatus(item)"
      v-for="item in statusLists"
      :key="item.type"
      :style="[item.color ? `color: ${item.color};`: '']"
      :class="['status-item', `${item.currentStatus || currentChoseStatus==item.type ? 'active' : ''}`, `${multiple? 'mb20': ''}`,`${item.color ? `background-${item.color}`: ''}`]"
    >
      <i
        v-if="item.icon||item.activeIcon"
        :class="['iconfont','mr9', item.currentStatus || currentChoseStatus==item.type ? item.icon: item.activeIcon,
         currentChoseStatus!=item.type && item.type=== 1 ? 'defaultActive0': '',
         currentChoseStatus!=item.type && item.type=== 2 ? 'defaultActive': '',
         currentChoseStatus!=item.type && !isDisabled && item.type=== 3 ? 'defaultActive2': '',
         isDisabled&&item.type=== 3 ? 'disabledActive' : ''
          ] "
      ></i>
      {{item.value}}
      <van-icon
        class="ml8"
        v-if="item.currentStatus || currentChoseStatus==item.type"
        name="success"
      />
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { Icon } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    statusList: {
      type: Array,
      default: () => [],
    },
    checkedData: {
      // 默认选中值
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const statusLists = ref(props.statusList);
    let currentChoseStatus = ref(null);
    const checkData = computed(() => {
      return ref(props.checkedData);
    });
    watch(
      checkData,
      (val) => {
        currentChoseStatus.value = val.value;
      },
      {
        immediate: true,
      }
    );
    if (props.multiple) {
      statusLists.value.map((item) => {
        return (item.currentStatus = false);
      });
    }
    let currentSelectList = ref([]); // 当前选中列表
    const currentChoseItem = ref(0);
    currentSelectList = computed(() => {
      return statusLists.value.filter((item) => {
        return item.currentStatus;
      });
    });
    const handleStatus = (item) => {
      if (props.multiple) {
        item.currentStatus = !item.currentStatus;
        context.emit("getStatusList", currentSelectList);
      } else {
        if (props.isDisabled && item.type == 3) {
          context.emit('disabledTip')
          return false;
        }
        currentChoseStatus.value = item.type;
        context.emit("getStatusList", item);
      }
    };
    return {
      currentChoseItem,
      handleStatus,
      checkData,
      currentSelectList,
      currentChoseStatus,
      statusLists,
    };
  },
};
</script>
<style lang="less" scoped>
.status-list-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // align-content: space-around;
  // justify-content: space-between;
  .status-item {
    margin-right: 12px;
    padding: 8px 24px;
    font-size: 24px;
    color: #999;
    border-radius: 30px;
    background: rgba(153, 153, 153, 0.1);
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0px;
    }
  }
  .active {
    color: #1678ff;
    background: rgba(22, 120, 255, 0.1);
    &::after {
      content: "";
    }
  }
  .defaultActive0 {
    color: #1678FF;
  }
  .defaultActive {
    color: #0fcb88;
  }
  .defaultActive2 {
    color: #00c0c0;
  }
  .disabledActive {
    opacity: 0.5;
  }
}
</style>