<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 18:07:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-07 20:23:59
-->
<template>
  <div class="status-list-wrapper">
    <div
      @click="handleStatus(item)"
      v-for="item in statusLists"
      :key="item.id"
      :class="['status-item', 'mb20',`${item.checked ? `background-${item.color} color-${item.color}`: ''}`]"
    >
      {{item.value}}
      <van-icon class="ml8" v-if="item.checked" name="success" />
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { Icon, Toast } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    statusList: {
      type: Array,
      default: () => [],
    },
    isClickTag: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const statusLists = ref(props.statusList);
    let currentChoseStatus = ref(null);
    let currentSelectList = ref([]); // 当前选中列表
    const currentChoseItem = ref(0);
    currentSelectList = computed(() => {
      return statusLists.value.filter((item) => {
        return item.checked;
      });
    });
    // 清除其它标签选中
    const clearTigs = () => {
      statusLists.value.forEach((item) => {
        item.checked = false;
      });
    };
    const handleStatus = (item) => {
      if (props.multiple) {
        if (item.checked) {
          item.checked = !item.checked;
          context.emit("getStatusList", currentSelectList);
        } else {
          if (props.isClickTag) {
            item.checked = !item.checked;
            context.emit("getStatusList", currentSelectList);
          } else {
            return Toast({
              message: "最多只能选择五个标签！",
            });
          }
        }
      } else {
        if (props.isDisabled) {
          return false;
        }
        clearTigs();
        if (item.checked) {
          item.checked = false;
        } else {
          item.checked = true;
        }
        context.emit("getStatusList", currentSelectList);
      }
    };
    return {
      currentChoseItem,
      handleStatus,
      currentSelectList,
      currentChoseStatus,
      statusLists,
    };
  },
};
</script>
<style lang="less" scoped>
.status-list-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // align-content: space-around;
  // justify-content: space-between;
  .status-item {
    margin-right: 12px;
    padding: 8px 24px;
    font-size: 24px;
    color: #999;
    border-radius: 30px;
    background: rgba(153, 153, 153, 0.1);
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0px;
    }
  }
}
</style>