<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-20 11:19:27
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-20 14:54:52
-->
<template>
  <div class="up-item">
    <p class="up-content" @click="handleUp">
      <van-icon name="arrow" v-if="!chooseStatus" />
      <van-icon name="arrow-down" v-else />
      {{childObj.name}}
    </p>
    <div class="down-item" v-if="chooseStatus">
      <child-item
        v-if="childObj.userList && childObj.userList.length"
        :childrenList="childObj.userList"
      ></child-item>
      <div class="other-down" v-if=" childObj.children&&childObj.children.length">
        <s-tree v-for="item in childObj.children" :key="item.id" :childObj="item"></s-tree>
      </div>
    </div>
  </div>
</template>
<script>
// import { ref } from "vue";

// import sTree from "./tree";
import childItem from "./childItem";
import { ref } from "vue";
import { Icon } from "vant";
export default {
  name: "sTree",
  props: {
    childObj: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    childItem,
    [Icon.name]: Icon,
  },
  setup() {
    const chooseStatus = ref(true);

    const handleUp = () => {
      chooseStatus.value = !chooseStatus.value;
    };
    return {
      chooseStatus,
      handleUp,
    };
  },
};
</script>
<style lang="less" scoped>
.up-item {
  box-sizing: border-box;
  .up-content {
    padding: 0px 24px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    font-weight: bold;
    background: #fff;
  }
  .down-item {
    padding: 0px 24px;
    box-sizing: border-box;
    background: #f8f8f8;
    // .other-down {
    //   padding: 0px 48px;
    // }
  }
}
</style>