
<template>
  <div class="nav-wrapper">
    <span class="nav">{{title}}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>
<style lang="less" scoped>
.nav-wrapper {
  .nav {
    position: relative;
    font-size: 32px;
    color: #333333;
    font-weight: bold;
    margin-left: 24px;
    &::before {
      content: "";
      position: absolute;
      left: -24px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 32px;
      background: #1678ff;
    }
  }
}
</style>