// 订单状态
export const orderStatusList = [
  {
    label: '待支付',
    value: 1,
  },
  {
    label: '优惠待审批',
    value: 2,
  },
  {
    label: '优惠审批被驳回',
    value: 3,
  },
  {
    label: '待财务审核',
    value: 4,
  },
  {
    label: '财务审核被驳回',
    value: 5,
  },
  {
    label: '已开通',
    value: 6,
  },
  {
    label: '订单关闭',
    value: 7,
  },
];
// 文件类型
export const fileTypeList = [
  {
    label: 'doc',
    icon: '/imgs/icon_word@2x.png',
  },
  {
    label: 'docx',
    icon: '/imgs/icon_word@2x.png',
  },
  {
    label: 'xls',
    icon: '/imgs/icon_excel@2x.png',
  },
  {
    label: 'xlsx',
    icon: '/imgs/icon_excel@2x.png',
  },
  {
    label: 'ppt',
    icon: '/imgs/icon_ppt@2x.png',
  },
  {
    label: 'pptx',
    icon: '/imgs/icon_ppt@2x.png',
  },
  {
    label: 'pdf',
    icon: '/imgs/icon_pdf@2x.png',
  },
];
export const buttonList = [
  {
    icon: 'hxb-icon_genjin',
    title: '添加跟进',
    url: '/follow',
  },
  {
    icon: 'hxb-icon_shangji',
    title: '添加商机',
    url: '/business',
  },
  {
    icon: 'hxb-icon_bianji',
    title: '编辑客户',
    url: '/edit',
  },
];

export const businessList = [
  {
    type: 1,
    title: '考籍类商机',
    detail: '适用于考籍需服务的考生，该类商机转为订单时，可选是否开通APP。',
  },
  {
    type: 2,
    title: 'APP类商机',
    detail:
      '适用于考籍无需开通、仅开通APP的考生 (如购买单门课程的考生) ，该商机转为订单时，不可添加考籍。',
  },
];
export const followUpList = [
  {
    type: 1,
    value: '正在跟进',
  },
  {
    type: 2,
    value: '准备购买',
  },
  {
    type: 3,
    value: '准备付款',
  },
  // {
  //   type: 4,
  //   value: '成交',
  // },
  {
    type: 5,
    value: '暂时搁置',
  },
  {
    type: 6,
    value: '仅报名',
  },
  {
    type: 7,
    value: '已预缴费',
  }
];
export const businessStatusList = [
  {
    type: 1,
    value: '初步沟通',
  },
  {
    type: 2,
    value: '深入沟通',
  },
];
export const goodTypeList = [
  {
    type: 1,
    value: '整专业',
  },
  {
    type: 2,
    value: '单门课程',
  },
];
export const goodsBigTypeList = [
  {
    // 考籍类
    name: '自考本科',
    type: 2,
  },
  {
    name: '职业考证',
    type: 3,
  },
  {
    name: '升学培训',
    type: 4,
  },
  {
    name: '成教',
    type: 5,
  },
  {
    name: '网教',
    type: 6,
  },
];
export const consumeTypeList = [
  {
    label: '购买',
    value: 1,
  },
  {
    label: '升级',
    value: 2,
  },
  {
    label: '续费',
    value: 3,
  },
];
export const consumeTypeList2 = [
  {
    label: '购买',
    value: 0,
  },
  {
    label: '升级',
    value: 1,
  },
  {
    label: '直播计划',
    value: 2,
  },
];
export const buyTypeList = [
  {
    label: '支付宝',
    value: 1,
    icon: 'hxb-icon_zhifubao',
  },
  {
    label: '微信',
    value: 2,
    icon: 'hxb-icon_weixin',
  },
  {
    label: '免费开通',
    value: 3,
    icon: 'hxb-icon_mianfei',
  },
  {
    label: '线下支付',
    value: 4,
    icon: 'hxb-icon_xianxiazhifu',
  },
  {
    label: 'IOS内购',
    value: 5,
    icon: 'hxb-icon_xianxiazhifu',
  },
];
// 等级
export const levelList = [
  {
    label: '基础学',
    value: 0,
    color: 'base',
  },
  {
    label: '智慧学',
    value: 1,
    color: 'warning',
  },
  {
    label: '精品学',
    value: 3,
    color: 'success',
  },
  {
    label: '模块',
    value: 5,
    color: 'success',
  },
  {
    label: '单点',
    value: 6,
    color: 'success',
  },
];

// 学习状态
export const studyStatus = [
  {
    label: '正常',
    value: 1,
    color: 'color_base',
  },
  {
    label: '已过期',
    value: 2,
    color: 'color_error',
  },
  {
    label: '已考过',
    value: 3,
    color: 'color_success',
  },
  {
    label: '已关闭',
    value: 4,
    color: 'color_warning',
  },
  {
    label: '未开通',
    value: 5,
  },
];

// 异议类型
export const objectionTypeList = [
  {
    label: '信息变更类',
    value: 1,
  },
  {
    label: '费用类',
    value: 2,
  },
  {
    label: '成绩类',
    value: 3,
  },
  {
    label: '报考类',
    value: 4,
  },
  {
    label: '书籍类',
    value: 5,
  },
];

// 异议状态
export const objectionStatusList = [
  {
    label: '待确认',
    value: 1,
    color: 'warning',
  },
  {
    label: '处理中',
    value: 2,
    color: 'base',
  },
  {
    label: '已撤销',
    value: 3,
    color: 'cancel',
  },
  {
    label: '已完结',
    value: 4,
    color: 'success',
  },
];
export const sexList = [
  {
    value: '女',
    type: 2,
  },
  {
    value: '男',
    type: 3,
  },
];
export const payLists = [
  {
    icon: 'arrow',
    activeIcon: 'coupon-o',
    value: '支付宝支付',
    type: 1,
  },
  {
    icon: 'arrow',
    value: '微信支付',
    activeIcon: 'coupon-o',
    type: 2,
  },
  {
    icon: 'arrow',
    activeIcon: 'coupon-o',
    value: '线下支付',
    type: 3,
  },
];
export const payLists2 = [
  {
    icon: 'hxb-icon_lianjie3',
    activeIcon: 'hxb-icon_lianjie3',
    value: '链接支付',
    type: 1,
  },
  {
    icon: 'hxb-icon_weixinzhifu',
    value: '微信支付',
    activeIcon: 'hxb-icon_weixinzhifu',
    type: 2,
  },
  {
    icon: 'hxb-icon_xianxiazhifu',
    activeIcon: 'hxb-icon_xianxiazhifu',
    value: '线下支付',
    type: 3,
  },
];
export const studentDetailList = [
  {
    value: '在校生',
    type: 1,
  },
  {
    value: '社会生',
    type: 2,
  },
];
export const payTypeList = [
  {
    type: 1,
    value: '支付宝支付',
  },
  {
    type: 2,
    value: '微信支付',
  },
  {
    type: 3,
    value: '线下支付',
  },
  {
    type: 4,
    value: '免费开通',
  },
];
export const originList = [
  {
    name: '高校名单',
    type: 1,
  },
  {
    name: '官网访问',
    type: 2,
  },
  {
    name: '主动来电',
    type: 3,
  },
  {
    name: '客户介绍',
    type: 4,
  },
  {
    name: '朋友介绍',
    type: 5,
  },
  {
    name: '百度推广',
    type: 6,
  },
  {
    name: '头条推广',
    type: 7,
  },
  {
    name: '抖音推广',
    type: 8,
  },
  {
    name: '独立开发',
    type: 9,
  },
  {
    name: '其他渠道',
    type: 10,
  },
  {
    name: '企业微信',
    type: 11,
  },
  {
    name: '宣讲计划',
    type: 12,
  },
];
export const educationList = [
  {
    name: '本科',
    type: 1,
  },
  {
    name: '专科',
    type: 2,
  },
];
export const degreeList = [
  {
    name: '高起本',
    type: 3
  },
  {
    name: '专科',
    type: 2
  },
  {
    name: '本科',
    type: 1
  }
]
export const courseList = [
  {
    value: '整专业',
    type: 1,
  },
  {
    value: '单门课程',
    type: 2,
  },
];
export const nationList = [
  {
    value: '汉族',
  },
  {
    value: '壮族',
  },
  {
    value: '满族',
  },
  {
    value: '回族',
  },
  {
    value: '苗族',
  },
  {
    value: '维吾尔族',
  },
  {
    value: '土家族',
  },
  {
    value: '彝族',
  },
  {
    value: '蒙古族',
  },
  {
    value: '藏族',
  },
  {
    value: '布依族',
  },
  {
    value: '侗族',
  },
  {
    value: '瑶族',
  },
  {
    value: '朝鲜族',
  },
  {
    value: '白族',
  },
  {
    value: '哈尼族',
  },
  {
    value: '哈萨克族',
  },
  {
    value: '黎族',
  },
  {
    value: '傣族',
  },
  {
    value: '畲族',
  },
  {
    value: '傈僳族',
  },
  {
    value: '仡佬族',
  },
  {
    value: '东乡族',
  },
  {
    value: '高山族',
  },
  {
    value: '拉祜族',
  },
  {
    value: '水族',
  },
  {
    value: '佤族',
  },
  {
    value: '纳西族',
  },
  {
    value: '羌族',
  },
  {
    value: '土族',
  },
  {
    value: '仫佬族',
  },
  {
    value: '锡伯族',
  },
  {
    value: '柯尔克孜族',
  },
  {
    value: '达斡尔族',
  },
  {
    value: '景颇族',
  },
  {
    value: '毛南族',
  },
  {
    value: '撒拉族',
  },
  {
    value: '布朗族',
  },
  {
    value: '塔吉克族',
  },
  {
    value: '阿昌族',
  },
  {
    value: '普米族',
  },
  {
    value: '鄂温克族',
  },
  {
    value: '怒族',
  },
  {
    value: '京族',
  },
  {
    value: '基诺族',
  },
  {
    value: '德昂族',
  },
  {
    value: '保安族',
  },
  {
    value: '俄罗斯族',
  },
  {
    value: '裕固族',
  },
  {
    value: '乌孜别克族',
  },
  {
    value: '门巴族',
  },
  {
    value: '鄂伦春族',
  },
  {
    value: '独龙族',
  },
  {
    value: '塔塔尔族',
  },
  {
    value: '赫哲族',
  },
  {
    value: '珞巴族',
  },
];
export const levelList2 = [
  {
    value: '智慧学',
    type: 1,
  },
  {
    value: '精品学',
    type: 3,
  },
];
export const examTypeList = [
  {
    value: '统考',
    type: 1,
  },
  {
    value: '省考',
    type: 0,
  },
];
export const reportTypeList = [
  {
    id: 1,
    name: '智慧班',
  },
  {
    id: 3,
    name: '精品班',
  },
  {
    id: 10,
    name: '听课',
  },
  {
    id: 11,
    name: '刷题',
  },
  {
    id: 12,
    name: '答疑',
  },
  {
    id: 13,
    name: '背考点',
  },
  {
    id: 14,
    name: '背考题',
  },
  {
    id: 15,
    name: '单词本',
  },
  {
    id: 16,
    name: '短语本',
  },
  {
    id: 17,
    name: '公式本',
  },
  {
    id: 18,
    name: '考前模拟',
  },
  {
    id: 19,
    name: '历考精讲',
  },
  {
    id: 20,
    name: '专题本',
  },
  {
    id: 21,
    name: '重难直播',
  },
  {
    id: 22,
    name: '习题视频',
  },
  {
    id: 23,
    name: '知识点视频(关联精讲)',
  },
];
// 弹窗的社会型应用型
export const registerTypeList = [
  {
    type: 0,
    name: '应用型',
  },
  {
    type: 1,
    name: '社会型',
  },
];
// 选择的社会型应用型
export const registerTypeList2 = [
  {
    type: 2,
    value: '应用型',
  },
  {
    type: 1,
    value: '社会型',
  },
]
export const isWatingUseType = [
  {
    type: 1,
    name: '是',
  },
  {
    type: 2,
    name: '否',
  },
];
export const renewYearList = [
  {
    type: 1,
    name: '1年',
  },
  {
    type: 2,
    name: '2年',
  },
  {
    type: 3,
    name: '3年',
  },
  {
    type: 4,
    name: '4年',
  },
];
export const changeStatusList3 = [
  {
    label: '在读',
    value: 1
  },
  {
    label: '休学',
    value: 2
  },
  {
    label: '停学',
    value: 3
  },
  {
    label: '退学',
    value: 4
  },
  {
    label: '转学',
    value: 5
  },
  {
    label: '等待注册',
    value: 6
  },
  {
    label: '等待报名',
    value: 10
  },
  {
    label: '等待录取',
    value: 11
  },
  {
    label: '待缴学费',
    value: 12
  }
]