<template>
  <div class="edit-wrapper">
    <my-nav class="ml8" title="必填信息"></my-nav>
    <div class="edit-required-message mt25">
      <van-form>
        <van-field
          v-model="dataList.name"
          label="客户名称"
          input-align="right"
          required
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.sourceName"
          label="客户来源"
          @click="isShowClientFrom = true"
          input-align="right"
          right-icon="arrow"
          readonly
          clickable
          required
          placeholder="请输入"
        ></van-field>
        <van-field label="客户星级" input-align="right" required>
          <template #input>
            <van-rate
              v-model="dataList.star"
              size="16"
              gutter="10"
              color="#FF6543"
              void-icon="star"
            />
          </template>
        </van-field>
        <van-field
          v-model="dataList.statusName"
          label="客户状态"
          input-align="right"
          @click="openClientStatus"
          required
          readonly
          clickable
          right-icon="arrow"
        />
        <van-field
          v-model="dataList.ownerName"
          label="负责人"
          input-align="right"
          right-icon="arrow"
          @click="openOwner"
          required
          clickable
          placeholder="请选择"
        />
      </van-form>
    </div>
    <my-nav class="ml8 contact-type" title="联系方式"></my-nav>
    <div class="contact-wrapper mt25">
      <div class="tips">
        <div class="tips-content">
          <van-icon name="info" />
          <span class="tips-message">联系方式至少要填写一种！</span>
        </div>
      </div>
      <van-form>
        <van-field
          v-model="dataList.mobile"
          label="手机号"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.wx"
          label="微信号"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.qq"
          label="QQ号"
          input-align="right"
          placeholder="请输入"
        />
      </van-form>
    </div>
    <my-nav class="ml8 contact-type" title="选填信息"></my-nav>
    <div class="optional-wrapper mt25">
      <van-form>
        <!-- <van-field
          v-model="state.value1"
          label="协作人"
          @click="openCooperation"
          name="pattern"
          right-icon="arrow"
          input-align="right"
          placeholder="请选择"
          readonly
          clickable
        />-->
        <van-field
          label="添加标签"
          name="pattern"
          @click="addTipsList"
          right-icon="arrow"
          readonly
          clickable
        />
      </van-form>
      <div class="flags">
        <div
          :class="[
            'flags-item',
            `${item.color ? `background-${item.color} color-${item.color}` : ''}`,
          ]"
          v-for="item in dataList.labelList"
          :key="item.id"
        >
          {{ item.value }}
          <i @click="deleteCurrentFlag(item)" class="iconfont hxb-icon_x"></i>
        </div>
      </div>
    </div>
    <div class="other-message-wrapper">
      <van-form>
        <van-field
          v-model="dataList.customerType"
          label="学生类型"
          input-align="right"
          clickable
        >
          <template #input>
            <div class="status-list-wrapper">
              <my-status-list
                :statusList="studentDetailList"
                @getStatusList="getStatusList"
                :checkedData="dataList.customerType"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="dataList.college"
          label="在读高校"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.degreeName"
          @click="isShowEducation = true"
          label="现有学历"
          right-icon="arrow"
          input-align="right"
          placeholder="请选择"
          readonly
          clickable
        />
        <van-field
          v-model="dataList.department"
          label="院系"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.majorName"
          label="专业"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.classNo"
          label="班级"
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="dataList.roomInfo"
          label="寝室号"
          input-align="right"
          placeholder="请输入"
        />
      </van-form>
    </div>
    <van-action-sheet
      :show="isShowClientFrom"
      :actions="actions"
      @select="onSelectClient"
      @cancel="isShowClientFrom = false"
      title="客户来源"
    />
    <van-action-sheet
      :show="isShowClientStatus"
      :actions="actionClientStatusList"
      @select="onSelectClientStatus"
      @cancel="isShowClientStatus = false"
      title="客户状态"
    />
    <van-action-sheet
      :show="isShowEducation"
      :actions="educationList"
      @select="onSelectEducation"
      @cancel="isShowEducation = false"
      title="现有学历"
    />
    <van-popup v-model:show="isShowTree" position="bottom" :style="{ height: '60%' }">
      <div class="tree-wrapper">
        <my-tree
          v-if="isShowTree"
          :departmentLists="treeList"
          :currentId="dataList.owner"
        ></my-tree>
      </div>
    </van-popup>
    <van-popup
      v-model:show="isShowCooperation"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="tree-wrapper">
        <my-tree
          v-if="isShowCooperation"
          :departmentLists="cooperationList"
          :currentId="dataList.owner"
        ></my-tree>
      </div>
    </van-popup>
    <van-action-sheet
      :show="isShowTipList"
      @select="onSelect"
      @cancel="isShowTipList = false"
      title="添加标签"
    >
      <div class="tips-model-wrapper">
        <my-nav title="公共标签"></my-nav>
        <div class="tips-lists">
          <my-tips-list
            multiple
            :statusList="commonTagList"
            :isClickTag="isClickTag"
            @getStatusList="getCommon($event, 1)"
          ></my-tips-list>
        </div>
        <my-nav class="personal-tips" title="个人标签"></my-nav>
        <div class="tips-lists">
          <my-tips-list
            multiple
            :isClickTag="isClickTag"
            :statusList="personalTagList"
            @getStatusList="getCommon($event, 2)"
          ></my-tips-list>
        </div>
      </div>
      <div class="button-wrapper">
        <my-button-group
          rightText="确认"
          :isShowLeft="false"
          @handleButtonRight="handleTips"
        ></my-button-group>
      </div>
    </van-action-sheet>
    <div class="footer-wrapper">
      <my-button-group
        leftText="保存"
        :isShowRight="false"
        :successSubmitLeft="checkcheckDisableds"
        @handleButtonLeft="handleButtonLeft"
      ></my-button-group>
    </div>
  </div>
</template>
<script>
import myTree from "@/components/myTree";
import myNav from "@/components/nav";
import myTipsList from "@/components/edit/tipsList";
import {
  studentDetailList,
  originList,
  followUpList,
  educationList,
} from "@/utils/const";
import myStatusList from "@/components/statusList";
import myButtonGroup from "@/components/buttonGroup";
import { getDepartmentList, getLabelByType } from "@/api/common";
import { getCustomerDetails, updateCustomerInfo } from "@/api/index";
import { Form, Field, Rate, Icon, ActionSheet, Popup, Toast } from "vant";
import { reactive, ref, toRefs, getCurrentInstance, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "Edit",
  components: {
    myNav,
    myTree,
    myTipsList,
    myButtonGroup,
    myStatusList,
    [Form.name]: Form,
    [Field.name]: Field,
    [Rate.name]: Rate,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    let checkcheckDisableds = ref(true);
    const loading = ref(false);
    const isCooperation = ref(false);
    let totalTagList = ref([]); // 总共标签列表
    const isClickTag = ref(true); // 是否可以点击标签
    const currentChooseList = ref([]); // 当前选中列表
    const currentPersonalList = ref([]); // 个人标签列表
    const otherTagList = ref([]); // 不是自己选择的标签
    const currentChoseTagLists = reactive({
      currentChooseList,
      currentPersonalList,
      otherTagList,
    });
    const tagLists = reactive({
      commonTagList: [],
      personalTagList: [],
    });
    const modelStatus = reactive({
      isShowEducation: false, // 现有学历
      isShowTree: false, // 树形结构
      isShowClientStatus: false, //客户状态
      isShowClientFrom: false, //客户来源
      isShowTipList: false, // 标签
      isShowCooperation: false, // 协作人
    });
    const departmentLists = reactive({
      treeList: {},
      cooperationList: {},
    });
    const store = useStore();
    const currentPersonal = computed(() => {
      return store.state.client.currentPersonal;
    });
    watch(currentPersonal, (val) => {
      if (!isCooperation.value) {
        state.dataList.ownerName = `${val.name}【${val.roleName}】`;
        state.dataList.owner = val.userRoleId;
        modelStatus.isShowTree = false;
      }
    });
    const clientStatusList = followUpList.map((item) => {
      return {
        name: item.value,
        type: item.type,
      };
    });
    const actionClientStatusList = ref(clientStatusList);
    const state = reactive({
      dataList: {
        customerType: "",
      },
    });
    let oldDataList = reactive({
      oldList: {},
    });
    const fillData = (data) => {
      let {
        name,
        sourceValue,
        mobile,
        wx,
        qq,
        id,
        statusValue,
        star,
        labelList,
        customerType,
        college,
        degree,
        department,
        majorName,
        classNo,
        roomInfo,
        userRoleId,
        degreeName,
        statusName,
        sourceName,
      } = data;
      labelList = labelList.map((item) => {
        return {
          color: item.color,
          id: item.id,
          value: item.content,
          checked: false,
        };
      });
      totalTagList.value.push(...labelList);
      state.dataList = reactive({
        name,
        mobile,
        wx,
        qq,
        star,
        id,
        customerType,
        college,
        degree,
        department,
        majorName,
        classNo,
        roomInfo,
        labelList,
        owner: userRoleId,
        source: sourceValue,
        status: statusValue,
        degreeName,
        statusName,
        sourceName,
      });
      oldDataList.oldList = JSON.parse(JSON.stringify(state.dataList));
    };
    const postData = () => {
      state.dataList.customerType = Number(state.dataList.customerType);
      const para = {};
      for (const key in state.dataList) {
        const isequal = proxy.$lodash.isEqual(
          state.dataList[key],
          oldDataList.oldList[key]
        );
        if (!isequal && state.dataList[key]) {
          para[key] = state.dataList[key];
        }
      }
      const arr = Object.keys(para);
      arr.forEach((item) => {
        if (
          item === "degreeName" ||
          item === "statusName" ||
          item === "sourceName" ||
          item === "ownerName"
        ) {
          delete para[item];
        }
      });
      return para;
    };
    const onSelectEducation = (val) => {
      state.dataList.degreeName = val.name;
      state.dataList.degree = val.type;
      modelStatus.isShowEducation = false;
    };
    const getDepartmentLists = (list) => {
      getDepartmentList().then((res) => {
        if (res && res.body) {
          departmentLists.treeList = res.body;
          let obj = checkCurrentPersonal(list.userRoleId, res.body);
          state.dataList.ownerName = `${obj.name}【${obj.roleName}】`;
        }
      });
    };
    watch(
      state,
      (val) => {
        // 有改变且必填项不为空
        let currentChange = postData();
        const arr = Object.keys(currentChange);
        if (arr.length) {
          if (
            val.dataList.name &&
            val.dataList.sourceName &&
            val.dataList.star &&
            val.dataList.statusName &&
            val.dataList.ownerName
          ) {
            if (val.dataList.wx || val.dataList.qq || val.dataList.mobile) {
              checkcheckDisableds.value = false;
            } else {
              checkcheckDisableds.value = true;
            }
          } else {
            checkcheckDisableds.value = true;
          }
        } else {
          checkcheckDisableds.value = true;
        }
      },
      { deep: true }
    );
    const checkLabelList = (arr, obj) => {
      // 标签没有改变也要传入标签ID
      if (!arr.includes("labelList")) {
        obj.labelList = oldDataList.oldList.labelList.map((item) => item.id);
      } else {
        if (obj.labelList.length) {
          obj.labelList = obj.labelList.map((item) => item.id);
        }
      }
    };
    const handleButtonLeft = () => {
      loading.value = true;
      const editParams = postData();
      const arr = Object.keys(editParams);
      checkLabelList(arr, editParams);
      editParams.id = state.dataList.id;
      updateCustomerInfo(editParams).then((res) => {
        if (res && res.body) {
          loading.value = false;
          Toast({
            message: "编辑成功!",
          });
          setTimeout(() => {
            router.push({
              path: "/client",
            });
          });
        }
      });
    };
    const getLabelByTypes = (type, cb) => {
      // 获取标签
      getLabelByType({
        type: type, // 1公共标签 2 个人标签
      }).then((res) => {
        cb(res);
      });
    };
    const getCustomerDetail = () => {
      let id = localStorage.getItem("customerId");
      getCustomerDetails({ customerId: id }).then((res) => {
        if (res && res.body) {
          fillData(res.body);
          getDepartmentLists(res.body);
          getLabelByTypes(1, (res) => {
            if (res && res.body) {
              tagLists.commonTagList = res.body.map((item) => {
                return {
                  id: item.id,
                  value: item.content,
                  color: item.color,
                  checked: false,
                };
              });
              getLabelByTypes(2, (res) => {
                if (res && res.body) {
                  tagLists.personalTagList = res.body.map((item) => {
                    return {
                      id: item.id,
                      value: item.content,
                      color: item.color,
                      checked: false,
                    };
                  });
                  getTypeTagList();
                }
              });
            }
          });
        }
      });
    };
    // 检测当标签数组长度
    const checkCurrentTagLength = (currentArr) => {
      if (currentArr.length > 4) {
        isClickTag.value = false;
      } else {
        isClickTag.value = true;
      }
    };
    watch(
      currentChoseTagLists,
      (val) => {
        const currentTotalList = [
          ...val.currentChooseList,
          ...val.currentPersonalList,
          ...val.otherTagList,
        ];
        checkCurrentTagLength(currentTotalList);
      },
      {
        deep: true,
        immediate: true,
      }
    );
    // 获取公共标签
    const checkList = reactive({
      currentPersonalList: JSON.parse(JSON.stringify(currentPersonalList.value)),
      currentChooseList: JSON.parse(JSON.stringify(currentChooseList.value)),
    });
    watch(
      checkList,
      (val) => {
        const totalList = [
          ...val.currentPersonalList,
          ...val.currentChooseList,
          ...otherTagList.value,
        ];
        checkCurrentTagLength(totalList);
      },
      {
        deep: true,
        immediate: true,
      }
    );
    getCustomerDetail();
    const actions = ref(originList);
    const startNumber = ref(1);
    const onSelectClient = (value) => {
      state.dataList.source = value.type;
      state.dataList.sourceName = value.name;
      modelStatus.isShowClientFrom = false;
    };
    const onSelectClientStatus = (value) => {
      state.dataList.statusName = value.name;
      state.dataList.status = value.type;
      modelStatus.isShowClientStatus = false;
    };
    let detailObj = {};
    const checkCurrentPersonal = (id, list) => {
      if (list.userList && list.userList.length) {
        list.userList.forEach((item) => {
          if (item.userRoleId === id) {
            detailObj = item;
          }
        });
      }
      if (list.children && list.children.length) {
        list.children.forEach((item) => {
          checkCurrentPersonal(id, item);
        });
      }
      return detailObj;
    };
    const getStatusList = (val) => {
      state.dataList.customerType = val.type;
    };
    const openOwner = () => {
      isCooperation.value = false;
      modelStatus.isShowTree = true;
    };
    const openCooperation = () => {
      const objCooper = JSON.parse(JSON.stringify(departmentLists.treeList));
      // 过滤协作人列表
      filterCooperation(objCooper, state.dataList.owner);
      departmentLists.cooperationList = objCooper;
      isCooperation.value = true;
      modelStatus.isShowCooperation = true;
    };
    // 过滤
    const filterCooperation = (obj, filterId) => {
      if (obj.children && obj.children.length) {
        obj.children.forEach((item) => {
          filterCooperation(item, filterId);
        });
      }
      if (obj.userList && obj.userList.length) {
        obj.userList.forEach((ele, index) => {
          if (ele.userRoleId === filterId) {
            obj.userList.splice(index, 1);
          }
        });
      }
    };
    const openClientStatus = () => {
      if (state.dataList.statusValue === 4) {
        return false;
      }
      modelStatus.isShowClientStatus = true;
    };
    const getCommon = (currentArr, type) => {
      if (type === 1) {
        checkList.currentChooseList = JSON.parse(JSON.stringify(currentArr.value));
      } else {
        checkList.currentPersonalList = JSON.parse(JSON.stringify(currentArr.value));
      }
    };
    const handleTips = () => {
      currentPersonalList.value = JSON.parse(
        JSON.stringify(checkList.currentPersonalList)
      );
      currentChooseList.value = JSON.parse(JSON.stringify(checkList.currentChooseList));
      let totalList = [
        ...currentPersonalList.value,
        ...currentChooseList.value,
        ...otherTagList.value,
      ];
      state.dataList.labelList = totalList;
      modelStatus.isShowTipList = false;
    };
    // 删除其它字段里面的标签
    const deleteOtherTagList = (item) => {
      const index1 = currentPersonalList.value.findIndex((el) => el.id === item.id);
      const index2 = currentChooseList.value.findIndex((el) => el.id === item.id);
      const index3 = otherTagList.value.findIndex((el) => el.id === item.id);
      if (index1 !== -1) {
        currentPersonalList.value.splice(index1, 1);
      }
      if (index2 !== -1) {
        currentChooseList.value.splice(index2, 1);
      }
      if (index3 !== -1) {
        otherTagList.value.splice(index3, 1);
      }
    };
    const deleteCurrentFlag = (item) => {
      const index = state.dataList.labelList.findIndex((el) => el.id === item.id);
      deleteOtherTagList(item);
      state.dataList.labelList.splice(index, 1);
      const index2 = otherTagList.value.findIndex((el) => el.id === item.id);
      if (index2 !== -1) {
        otherTagList.value.splice(index2, 1);
      }
      if (state.dataList.labelList.length > 4) {
        isClickTag.value = false;
      } else {
        isClickTag.value = true;
      }
    };
    const reset = () => {
      tagLists.commonTagList.forEach((item) => (item.checked = false));
      tagLists.personalTagList.forEach((item) => (item.checked = false));
    };
    // 其它人选择的标签
    const getTypeTagList = () => {
      const totalTagLists = [...tagLists.commonTagList, ...tagLists.personalTagList];
      // 获取其它人添加的标签
      state.dataList.labelList.forEach((ele) => {
        let flag = totalTagLists.some((item) => {
          return ele.id === item.id;
        });
        if (!flag) {
          otherTagList.value.push(ele);
        }
      });
      // 获取公共标签默认值
      state.dataList.labelList.forEach((ele) => {
        let flag = tagLists.commonTagList.some((item) => {
          return ele.id === item.id;
        });
        if (flag) {
          currentChooseList.value.push(ele);
        }
      });
      // 获取个人标签默认值
      state.dataList.labelList.forEach((ele) => {
        let flag = tagLists.personalTagList.some((item) => {
          return ele.id === item.id;
        });
        if (flag) {
          currentPersonalList.value.push(ele);
        }
      });
    };
    // 标签默认选中值
    const addTipsList = () => {
      reset();
      // 公共标签默认选中
      state.dataList.labelList.forEach((item) => {
        tagLists.commonTagList.forEach((ele) => {
          if (item.id === ele.id) {
            ele.checked = true;
          }
        });
        tagLists.personalTagList.forEach((el) => {
          if (item.id === el.id) {
            el.checked = true;
          }
        });
      });
      // 重置获取点击的标签的数组
      console.log(currentChooseList.value);
      console.log(currentPersonalList.value);
      checkList.currentChooseList = JSON.parse(JSON.stringify(currentChooseList.value));
      checkList.currentPersonalList = JSON.parse(
        JSON.stringify(currentPersonalList.value)
      );
      modelStatus.isShowTipList = true;
    };
    return {
      state,
      startNumber,
      handleButtonLeft,
      getStatusList,
      onSelectEducation,
      studentDetailList,
      educationList,
      openCooperation,
      onSelectClient,
      onSelectClientStatus,
      checkcheckDisableds,
      handleTips,
      openOwner,
      actions,
      openClientStatus,
      addTipsList,
      isClickTag,
      getCommon,
      deleteCurrentFlag,
      actionClientStatusList,
      ...toRefs(modelStatus),
      ...toRefs(state),
      ...toRefs(departmentLists),
      ...toRefs(tagLists),
    };
  },
};
</script>
<style lang="less" scoped>
.edit-wrapper {
  min-height: 100%;
  background: #f5f5f5;
  padding: 40px 24px;
  .edit-required-message,
  .contact-wrapper {
    background: #fff;
    border-radius: 24px;
    padding: 0px 32px 0px 40px;
    box-sizing: border-box;
    .tips {
      padding-top: 24px;
      .tips-content {
        background: rgba(22, 120, 255, 0.1);
        padding: 12px 21px;
        box-sizing: border-box;
        color: #1678ff;
        display: flex;
        align-items: center;
        border-radius: 12px;
        .tips-message {
          margin-left: 17px;
        }
      }
    }
  }
  .contact-type {
    padding-top: 48px;
  }
  .optional-wrapper {
    padding: 0px 32px 41px 32px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 24px;
    .flags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .flags-item {
        margin-right: 12px;
        padding: 8px 24px;
        font-size: 24px;
        border-radius: 30px;
        margin-bottom: 12px;
      }
    }
  }
  .other-message-wrapper {
    margin-top: 20px;
    margin-bottom: 150px;
    padding: 0px 32px;
    border-radius: 24px;
    background: #fff;
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
  }
  .client-from-wrapper {
    padding: 0px 32px;
    .client-item {
      padding: 35px 0px;
      text-align: center;
      border-bottom: 1px solid #eeeeee;
      &:last-child {
        border: inherit;
      }
    }
  }
  .tips-model-wrapper {
    padding: 32px 32px 120px 32px;
    .tips-lists {
      margin-top: 8px;
    }
    .personal-tips {
      margin-top: 64px;
    }
  }
  .button-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
  }
}
</style>
