<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-20 14:41:47
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-20 14:52:33
-->
<template>
  <div class="my-tree-wrapper">
    <tree-content :childObj="departmentLists"></tree-content>
  </div>
</template>
<script>
import treeContent from "./tree";
// import { toRefs } from "vue";
export default {
  props: {
    departmentLists: {
      type: Object,
      default: () => {},
    },
    currentId: {
      type: Number,
      default: -1,
    },
  },
  components: {
    treeContent,
  },
  setup(props) {
    const addCheckItem = (list) => {
      if (list.userList && list.userList.length) {
        list.userList.forEach((item) => {
          if (props.currentId === item.userRoleId) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
      if (list.children && list.children.length) {
        list.children.forEach((item) => {
          addCheckItem(item);
        });
      } else {
        return false;
      }
    };
    addCheckItem(props.departmentLists);
    return {};
  },
};
</script>
<style lang="less" scoped>
</style>