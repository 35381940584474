<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-20 11:50:23
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-20 16:26:09
-->
<template>
  <div class="childs-wrapper">
    <div class="childs-list" v-for="item in childrenList" :key="item.id">
      <div class="title">
        {{item.name}}
        <span class="childen-job">{{item.roleName}}</span>
      </div>
      <div class="choose">
        <van-checkbox v-model="item.checked" icon-size="20" @change="getCurrentChild(item)"></van-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox } from "vant";
// import { computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    childrenList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Checkbox.name]: Checkbox,
  },
  setup() {
    const store = useStore();
    const getCurrentChild = (item) => {
      store.commit("client/setPersonal", item);
    };
    return {
      getCurrentChild,
    };
  },
};
</script>
<style lang="less" scoped>
.childs-wrapper {
  padding: 0px 48px;
  box-sizing: border-box;
  .childs-list {
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 28px;
      color: #333;
      .childen-job {
        font-size: 20px;
        padding: 4px 20px;
        border-radius: 20px;
        background: rgba(22, 120, 255, 0.1);
        color: #1678ff;
      }
    }
  }
}
</style>
